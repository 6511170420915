<template>
    <a-layout-content class="Dashboard">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>Trang chủ</a-breadcrumb-item>
            <a-breadcrumb-item>Dashboard</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="Dashboard" :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px 25px', padding: '15px' }">
            <h1>Equipment</h1>
        </div>
        <a-layout>
            <a-layout-content>
                <div class="content-header">
                    <div class="content-header__right">
<!--                        <div class="bulk-action">-->
<!--                            <span>Bulk action:</span>-->
<!--                            <a-select :disabled="!bulkSelected" labelInValue placeholder="Chọn action" style="width: 160px; margin-left: 15px " @change="handleActionSelected">-->
<!--                                <a-select-option value="delete">Xóa {{ selectedRows.length }} câu hỏi</a-select-option>-->
<!--                                <a-select-option value="publish">Publish {{ selectedRows.length }} câu hỏi</a-select-option>-->
<!--                                <a-select-option value="unpublish">UnPublish {{ selectedRows.length }} câu hỏi</a-select-option>-->
<!--                            </a-select>-->
<!--                            <a-popconfirm title='Bạn có chắc chắn muốn thực hiện?' @confirm="handleBulkActionConfirm">-->
<!--                                <a-button  :disabled="!bulkSelected" style="margin-left: 15px" type="primary">Thực hiện</a-button>-->
<!--                            </a-popconfirm>-->
<!--                        </div>-->
                    </div>
                </div>
                <a-divider />
<!--                <equipment-table-list :loading="loading"  v-on:deleteItem="handleDeleteQuestion" v-on:multiSelected="handleMultiSelected" @publishChange="publishChange" @updateQuestion="handleUpdateQuestion" ref="equipmentlist"/>-->
                
            </a-layout-content>
        </a-layout>
<!--        <create-or-update :isUpdate="isUpdate"/>-->
    </a-layout-content>
</template>

<script>
    export default {
        props: {
        }
    };
</script>
